<template>
  <div class="shipping-address" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <div class="title font-md">
        <b>배송지 목록</b>
      </div>
      <div class="desc font-sm">
        <span>신규 배송지를 등록하시려면 배송지 추가 버튼을 눌러주세요.</span>
      </div>
      <div class="content">
        <template v-if="state.list.length">
          <ul class="tight font-sm">
            <li class="border-hover-point" v-for="(a, idx) in state.list" :key="idx" @click="state.loaded && select(a)">
              <div class="wrapper">
                <div>
                  <b>{{ a.addressName }}</b>
                  <span class="badge badge-point ml-2" v-if="a.defaultFlag === 'Y'">기본</span>
                </div>
                <div class="pt-2 pb-2">
                  <span>{{ a.name }}</span>
                  <span class="ml-1">{{ a.mobile }}</span>
                </div>
                <div>
                  <span>[{{ a.post }}] {{ a.addr1 }} {{ a.addr2 }}</span>
                </div>
                <div class="acts">
                  <button class="btn btn-default font-sm" @click.stop="edit(a)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="btn btn-default font-sm" @click.stop="remove(a)">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="page">
            <Pagination :info="state.paginationInfo" :change="load" v-if="state.paginationInfo"/>
          </div>
        </template>
        <NoData v-else/>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-point btn-block font-sm" @click="add()">배송지 추가</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import Pagination from "@/components/Pagination";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "modalShippingAddress";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {NoData, Pagination, Number},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});

      for (let i = 0; i < 3; i += 1) {
        state.list.push({
          addressName: "Wait a moment",
          name: "Wait a moment",
          mobile: "010-0000-0000",
          post: "00000",
          addr1: "Wait a moment",
          addr2: "Wait a moment",
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 5,
      },
      paginationInfo: null,
      count: 0,
      list: []
    });

    const select = (address) => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {
            modal,
            params: address
          });
        }
      });
    };

    const add = () => {
      if (state.paginationInfo.totalRecordCount >= 20) {
        return store.commit("setSwingMessage", "더 이상 등록할 수 없습니다.");
      }

      store.commit("openModal", {
        name: "ShippingAddressDetail",
        params: {name: "ShippingAddress"},
        callback: `${component.name}.load`
      });
    };

    const edit = (address) => {
      store.commit("openModal", {
        name: "ShippingAddressDetail",
        params: {address: address},
        callback: `${component.name}.load`
      });
    };

    const remove = (address) => {
      if (address.defaultFlag === "Y") {
        return store.commit("setSwingMessage", "기본 배송지는 삭제할 수 없습니다.");
      }

      store.commit("confirm", {
        message: "삭제하시겠습니까?",
        allow() {
          http.delete(`/api/member/${store.state.account.memberSeq}/address/${address.addressSeq}`).then(() => {
            store.commit("setSwingMessage", "배송지를 삭제하였습니다.");
            load();
          }).catch(httpError());
        }
      });
    };

    const load = (num) => {
      state.args.pageIndex = num ? num : 1;

      state.loaded = false;
      http.get(`/api/member/${store.state.account.memberSeq}/address`, state.args).then(({data}) => {
        state.loaded = true;
        state.list = data.body.list;
        state.paginationInfo = data.body.paginationInfo;
      }).catch(httpError());
    };

    return {component, state, select, remove, load, add, edit};
  },
});
</script>

<style lang="scss" scoped>
.shipping-address {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .content {
      > ul > li {
        cursor: pointer;
        padding: $px20;
        border: $px1 solid #eee;
        border-radius: $px4;
        margin-bottom: $px15;

        > .wrapper {
          position: relative;

          .acts {
            position: absolute;
            top: $px-7;
            right: 0;

            .btn {
              margin-left: $px3;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .more {
        padding-top: $px20;
        text-align: center;

        .btn {
          padding: $px15 $px25;
        }
      }

      .page {
        padding-top: $px15;
      }
    }
  }

  .action {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;

      span {
        vertical-align: middle;

        &.img {
          width: $px24;
          height: $px24;
          margin-left: $px8;
        }
      }
    }
  }

  &.skeleton {
    .wrapper {
      .title > b, .desc > span {
        @include skeleton;
      }

      .content > ul > li {
        cursor: default;

        > .wrapper {
          > div {
            b, span {
              @include skeleton;
            }
          }

          .acts .btn {
            @include skeleton;
          }
        }

        &:hover {
          border-color: #eee !important;
        }
      }
    }

    .action > .btn {
      @include skeleton;
    }
  }
}
</style>